<template>
  <builder-component model="page" api-key="5237e5c5d7ce471dad91b5c4addc40eb" entry="39bb1f2bdce04c38b58074194bb24b3c"></builder-component>
</template>
<script>

// use this if you are on Vue 2

export default {
  computed: {
    // Provide a default title and default description in case there is no content
    computedTitle () {
      return this.content?.data?.title || 'Default title';
    },
    computedDescription () {
      return this.content?.data?.description || 'Default description';
    }
  },
  data: () => ({
    canShowContent: false,
    content: null,
    apiKey: '5237e5c5d7ce471dad91b5c4addc40eb'
  }),
  mounted () {
    let builderScript = document.createElement('script')
    builderScript.setAttribute('src', 'https://cdn.builder.io/js/webcomponents')
    builderScript.setAttribute('async', true)
    document.head.appendChild(builderScript)
  }
}
</script>
