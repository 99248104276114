<template>
  <div class="reviews-listing">
    <SfHeading
      :title="$t('{count} Reviews', { count: reviewsCount })"
      :level="3"
      class="reviews-listing__heading"
    />
    <MReviewList
      :reviews="reviews"
      :product="{sku: 'all'}"
      :total-pages="totalReviewsPages"
      :loading="areReviewsLoading"
      :error="reviewsErrorMessage"
      @page-change="handlePageChange"
      sorting-method="photos"
      :should-reviews-refer-products="true"
    />
  </div>
</template>

<script>
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import MReviewList from '~/theme/components/molecules/m-review-list'
import { isServer } from '@vue-storefront/core/helpers';
import ProductReviewsMixin from '~/theme/mixins/product-reviews.js';
import { mapActions } from 'vuex';

export default {
  name: 'ReviewsListing',
  components: {
    MReviewList,
    SfHeading
  },
  mixins: [ProductReviewsMixin],
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  created () {
    if (isServer) return
    this.listReviews({ sku: 'all', page: 1, sortBy: 'photos' })
    this.reviewsSku = 'all'
  },
  computed: {
    areReviewsLoading () {
      return this.$store.state['stamped-io'].isLoading
    },
    reviewsErrorMessage () {
      return this.$store.state['stamped-io'].errorMessage
    },
    productSkus () {
      return ['100']
    }
  },
  methods: {
    ...mapActions('stamped-io', {
      listReviews: 'list'
    }),
    async handlePageChange ({ page, options, sortingMethod, currentPage }) {
      if (currentPage === page && !options.force) return
      this.listReviews({ sku: 'all', page, sortBy: 'photos' })
    }
  }
};
</script>
<style lang="scss" scoped>
  @import "~@storefront-ui/shared/styles/helpers/breakpoints";
  .reviews-listing {
    box-sizing: border-box;
    @include for-desktop {
      max-width: var(--container-width);
      margin: 0 auto;
    }
    &__heading {
      margin-top: var(--spacer-base);
    }
  }
</style>
