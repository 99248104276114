<template>
  <div id="static">
    <SfBreadcrumbs
      v-if="showBreadcrumbs"
      class="breadcrumbs desktop-only"
      :breadcrumbs="breadcrumbs"
    >
      <template #link="{breadcrumb}">
        <router-link :to="localizedRoute(breadcrumb.route.link)" class="sf-breadcrumbs__breadcrumb">
          {{ breadcrumb.text }}
        </router-link>
      </template>
    </SfBreadcrumbs>
    <AStatic :content="content" />
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n';
import SfBreadcrumbs from '@storefront-ui/vue/src/components/atoms/SfBreadcrumbs/SfBreadcrumbs.vue';
import CmsPage from '@vue-storefront/core/pages/CmsPage';
import AStatic from '~/theme/components/atoms/a-static';

export default {
  name: 'Static',
  components: {
    SfBreadcrumbs,
    AStatic
  },
  mixins: [CmsPage],
  data () {
    return {
      showBreadcrumbs: false
    }
  },
  computed: {
    breadcrumbs () {
      return [
        { text: i18n.t('Homepage'), route: { link: this.localizedRoute('/') } },
        { text: this.content.title }
      ];
    },
    content () {
      return {
        title: this.$store.state.cmsPage.current?.title,
        message: this.$store.state.cmsPage.current?.content
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#static {
  box-sizing: border-box;
  @include for-desktop {
    max-width: var(--container-width);
    padding: 0 var(--spacer-sm);
    margin: 0 auto;
  }
}
.breadcrumbs {
  margin: var(--spacer-base) auto var(--spacer-lg);
}
</style>
